import axios from 'axios';
import { Loading, Message } from 'element-ui';




var loading;
let loading_num = 0 // 多个请求同时触发不影响loading层关闭
axios.default.timeout = 30000


axios.interceptors.request.use(function (config) {
  
  if ( !Object.prototype.hasOwnProperty.call(config, 'noLoading') || config.noLoading === false ) {
    if(loading_num === 0){
      loading = Loading.service({ fullscreen: true, background: 'rgba(0,0,0,0)' });
    }
    loading_num += 1
  }
  // 权鉴验证
  // config.headers['Authorization'] = getToken() || getTokenInSessionStorage() || ''

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  loading_num = loading_num > 0 ? loading_num - 1 : 0
  if (typeof (loading) == 'object' && loading_num == 0) {
    loading.close();
  }
  if(response.config.isStream){
    return response
  }
  if (typeof response.data.code === 'undefined') {
    return response.data
  }
  if(response.config.errorCode === response.data.code){
    return response.data
  }

  // Do something with response data  ACCESS_TOKEN_INVALID
  

  if(response.data.code === 1000){
    return Promise.reject(response)
  }


  if(response.config.getAllResponse){
    return response.data
  }
  if(response.config.handleCode === response.data.code){
    return response.data
  }
  if(response.data.code === 0){
    return response.data.data
  }else{
    Message.error(response.data.msg)
    return Promise.reject(response)
  }

  // return response.data;
}, function (error) {
  // Do something with response error
  loading_num = loading_num > 0 ? loading_num - 1 : 0
  if (typeof (loading) == 'object' && loading_num == 0) {
    loading.close();
  }
  console.log(error)
  // let msg = ''
  // if(error.response.data.details.length > 0){
  //   msg = error.response.data.details[0].field + ' ' + error.response.data.details[0].message
  // }
  Message.error('服务器异常')

  // if (error.response.data.code === 'ACCESS_TOKEN_INVALID' || error.response.data.code == 'REFRESH_TOKEN_INVALID') {
  //   Message.error({
  //     message: '请先登录！'
  //   });
  //   setTimeout(() => {
  //     window.location.href = '/';
  //   }, 1000)
  // }
  return Promise.reject(error);
});


export const get = (url, params, config) => {
  config = config === undefined ? {} : config;
  return axios.get(url, {params, ...config});
}

export const post = (url, data, config) => {
  config = config === undefined ? {} : config;
  return axios({url, method: 'post', data, ...config });
}

export const postGet = (url, params, config) => {
  config = config === undefined ? {} : config;
  return axios({url, method: 'post', params, ...config });
}

export const Delete = (url, data, config) => {
  config = config === undefined ? {} : config;
  return axios({url, method: 'delete', data, ...config });
}

export const patch = (url, data, config) => {
  config = config === undefined ? {} : config;
  return axios({url, method: 'patch', data, ...config });
}

export const put = (url, data, config) => {
  config = config === undefined ? {} : config;
  return axios({url, method: 'put', data, ...config });
}