<template>
  <div class="home">
    <div class="header">
      <a :href="index" target="_blank" class="logo">
        <img :src="logo" alt="互联二维码">
      </a>
      <el-divider direction="vertical"></el-divider>
      <span>文件传输助手</span>
    </div>
    <div class="body">
      <div class="trans-box">
        <div class="code-con" v-if="isReceive === 0">
          <h3 class="title">微信扫码</h3>
          <p class="desc">把二维码中文件传输至电脑</p>
          <div class="img-box">
            <div class="cover" v-if="timeout">
              <div class="txt">
                <span><svg-icon icon-class="alert"></svg-icon> 已超时</span> 
                <el-button type="primary" plain @click="init" >点击刷新</el-button>
              </div>
            </div>
            <el-image
            class="code"
            style="width: 256px; height: 256px"
            v-loading="loading.img"
            :src="img"
            fit="cover"></el-image>
          </div>
          <p class="tip">10分钟内有效，截图保存无效</p>


          <!-- <el-button type="primary" @click="uploadFile" style="margin-top: 20px;" >测试上传文件</el-button>
          <el-button @click="testTimeOut">超时测试</el-button> -->


        </div>
        <div class="file-con" v-if="isReceive === 1">
          <div class="back" @click="goBack">
            <span class="el-icon-arrow-left"></span>返回
          </div>
          <dl>
            <dd>
              <img :src="iconList[fileInfo.type]" alt="">
            </dd>
            <dt style="margin-top: 20px;">{{fileInfo.fileName}}</dt>
            <dt style="margin-top: 60px;">
              <el-button type="primary" @click="download" >下载</el-button>
            </dt>
          </dl>
        </div>
        <div class="result-con" v-if="isReceive === 2">
          <dl>
            <dd>
              <svg-icon icon-class="form-radio"></svg-icon>
            </dd>
            <dt>
              <h3>文件已开始下载</h3>
              <p>未开始下载？<span @click="download" class="link">重新下载</span></p>
            </dt>
            <dt>
              <el-button class="back-btn" @click="goBack" >返回扫码</el-button>
            </dt>
          </dl>
          
        </div>
      </div>
    </div>
    <div class="footer">
      <p>
        Copyright © 2022 福州众远达科技有限公司   
        <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备20014153号</a>  
        <a href="https://www.hlcode.cn/help/NiT9z0O" target="_blank">免责声明</a> 
      </p>
    </div>
    
  </div>
</template>

<script>

import JSEncrypt from 'jsencrypt'
import {pubKey} from '@/utils/auth'
import {getKey, getFile, uploadFile} from '@/api'
import QRCode from 'qrcode'
export default {
  name: 'Home',
  data(){
    return {
      fileTypeList: {
        image: ['png','jpg','jpeg','gif', 'bmp'],
        word: ['doc','docx', 'dot', 'dotx', 'wps', 'wpt', 'rft'],
        pdf: ['pdf'],
        ppt: ['ppt','pptx','pot', 'potx','dps','dpt', "ppsx"],
        excel: ['xls','xlsx','csv', 'et', 'ett'],
        txt: ['txt', 'rtf']
      },
      iconList: { // 上传文件图标
        image: '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-image.png',
        excel: '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-excel.png', 
        pdf: '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-pdf.png', 
        ppt: '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-ppt.png', 
        txt: '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-txt.png', 
        word: '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-word.png',
        other:  '//hulianqrcode.oss-cn-hangzhou.aliyuncs.com/web/pc/editor/icon-file-other.png',
      },
      logo: process.env.VUE_APP_LOGO,
      index: process.env.VUE_APP_INDEX_URL,
      key: '',
      img: "",
      keyTimmer: '',
      timmer: '',
      // fileInfo: {
      //   fileUrl: 'http://baidu.com',
      //   fileName: '测试文件sad客服了解阿什拉夫啊善良的开发商两地分居卢萨卡地方.ppt'
      // },
      fileInfo: "",
      duration: 10*60*1000,
      step: 3000,
      loading: {
        img: true
      },
      isReceive: 0,
      timeout: 0
    }
  },
  created(){
    document.title = "文件传输助手 – 互联二维码"
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){
      this.isReceive = 0
      this.timeout = 0
      this.getKey()
      if(this.keyTimmer){
        clearInterval(this.keyTimmer)
      }
      if(this.keyTimmer){
        clearTimeout(this.keyTimmer)
      }
      this.keyTimmer = setTimeout(()=>{ // 超时
        if(this.isReceive === 0){
          this.timeout = 1
          clearInterval(this.timmer) //停止请求key
          clearTimeout(this.keyTimmer)
        }
      }, this.duration)
    },
    testTimeOut(){
      this.timeout = 1
      clearInterval(this.timmer) //停止请求key
      clearTimeout(this.keyTimmer)
    },
    async getBlobUrl(url){
      return new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        //配置请求方式、请求地址以及是否同步
        xhr.open('get', url, true);
        //设置请求结果类型为blob
        xhr.responseType = 'blob';
        //请求成功回调函数
        xhr.onload = function() {
            if (this.status == 200) {
                //获取blob对象
                var blob = this.response;
                //获取blob对象地址，并把值赋给容器
                // document.getElementById("video").src = URL.createObjectURL(blob);
                resolve(URL.createObjectURL(blob))
            }
        };
        xhr.send();
      })
      
    },
    async download(){
      this.isReceive = 2
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = this.fileInfo.fileName
      a.href = await this.getBlobUrl(this.fileInfo.fileUrl)
      a.target = '_blank'
      a.dispatchEvent(event)
      a = null
    },
    goBack(){
      this.isReceive = 0
      this.fileInfo = ''
      this.init()
    },
    async uploadFile(){
      await uploadFile({
        fileList: JSON.stringify([{fileUrl: 'https://oss.hlcode.cn/server/code/img/2022/08/08/18390167730497.jpeg', fileName: '测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件测试文件.jpg'}]),
        validKey: this.key
      })
    },
    setPassword(password) {
      let encrypt = new JSEncrypt()
      encrypt.setPublicKey(pubKey);
      return encrypt.encrypt(password)
    },
    async getFile(){
      this.timmer = setInterval(async () => {
        let {isReceive, list} = await getFile({
          did: this.setPassword(this.key),
          validKey: this.key
        },{noLoading: true})
        this.isReceive = isReceive
        if(isReceive){
          this.fileInfo = list[0]
          let type = list[0].fileUrl.split(".").pop()
          for(let t in this.fileTypeList){
            if(this.fileTypeList[t].includes(type)){
              this.fileInfo.type = t
              break 
            }
          }
          if(!this.fileInfo.type){
            this.fileInfo.type = "other"
          }
          
          clearInterval(this.timmer)
        }
      }, this.step)
      
    },
    
    async getKey(){
      this.loading.img = true
      let {key} = await getKey({
        did: this.setPassword(new Date().getTime()),
        validKey: ''
      }, {noLoading: true})
      this.key = key
      QRCode.toDataURL(`https://www.qr111.cn/?id=${key}`,{ errorCorrectionLevel: 'H', margin: 2, width: 256 })
      .then(url => {
        this.img = url
        if(this.timmer){
          clearInterval(this.timmer)
        }
        this.getFile()
        this.loading.img = false
      })
      .catch(err => {
        console.error(err)
        this.loading.img = false
      })
      
    }
  }
}
</script>

<style lang="scss">
html, body{
  margin: 0;
  padding: 0;
}
.home{
  min-height: 100vh;
  background: #EFF1F4;
  .header{
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 32px;
  }
  .logo{
    img{
      height: 32px;
      vertical-align: -8px;
    }
  }
  .trans-box{
    @include abCenter;
    width: 370px;
    min-height: 420px;
    padding: 26px 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px 0px rgba(0,19,11,0.0600);
    border-radius: 12px;
    text-align: center;

    .title{
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
    }
    .desc{
      margin-top: 15px;
      font-size: 20px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
    .tip{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .img-box{
      position: relative;
      margin: 20px auto auto auto;
      width: 256px;
      height: 256px;
      overflow: hidden;
      .cover{
        position: absolute;
        left: 0;
        top: 0;
        width: 256px;
        height: 256px;
        background: rgba(255,255,255,.9);
        z-index: 9;
        .txt{
          @include abCenter;
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          svg{
            font-size: 28px;
            vertical-align: -6px;
          }
          .el-button{
            padding: 0;
            margin-top: 24px;
            width: 90px;
            height: 28px;
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
    }
    .code{
      width: 256px;
      height: 256px;
      img{
        width: 256px;
        height: 256px;
      }
    }
    .code-con{

    }
    .result-con{
      dl{
        @include abCenter;
      }
      dd{
        padding:0;
        margin: 0;
        font-size: 60px;
        color: #2DAF60;
      }
      dt{
        h3{
          margin-top: 24px;
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          line-height: 24px;
        }
        p{
          margin-top: 15px;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
        }
        .el-button{
          margin-top: 70px;
          width: 240px;
          height: 46px;
          font-size: 18px;
          font-weight: 500;
          color: #666;
          &:hover{
            color: $primary;
          }
        }
      }
    }
    .file-con{
      .back{
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
      img{
        width: 90px;
      }
      dl{
        @include abCenter;
      }
      dd{
        padding:0;
        margin: 0;
      }
      dt{
        margin: 0 auto auto auto;
        width: 287px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        @include ellipsis(4);
        .el-button{
          width: 240px;
          height: 46px;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
  .footer{
    position: fixed;
    bottom: 28px;
    left: 0;
    width: 100%;
    height: 14px;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: #999999;
    text-align: center;
    a{
      margin-left: 12px;
      text-decoration: none;
      color: #999;
    }
  }
}
</style>
