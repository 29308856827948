<template>
  <div id="app">
    <div v-if="ready">
      <div v-if="isMobile">
        <Mobile/>
      </div>
      <div v-else>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Mobile from '@/views/Mobile'
export default {
  data(){
    return {
      ready: false,
      isMobile: false
    }
  },
  components: {Mobile},
  created(){
    this.init()
  },
  methods: {
    init(){
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
      var isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
      var isAndroid = ua.match(/(Android)\s+([\d.]+)/);
      this.isMobile = isIphone || isAndroid;
      this.ready = true
    }
  }
}
</script>

<style>

</style>
