<template>
  <div class="mobile">
    <div class="header">
      <a :href="index" target="_blank" class="logo">
        <img :src="logo" alt="互联二维码">
      </a>
      <el-divider direction="vertical"></el-divider>
      <span>文件传输助手</span>
    </div>
    <div class="body">
      <dl>
        <dd>
          <svg-icon icon-class="icon-computer"></svg-icon>
        </dd>
        <dt>
          <h4>请使用电脑浏览器访问</h4>
          <p>在电脑浏览器访问 <span class="link">qr111.cn</span></p>
        </dt>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      logo: process.env.VUE_APP_LOGO,
      index: process.env.VUE_APP_INDEX_URL,
    }
  },
}
</script>

<style lang="scss">
.mobile{
  .header{
    padding: 3.2vw 2.6vw;
    font-size: 3.5vw;
    font-weight: 600;
    color: #333333;
    line-height: 5.3vw;
    background: #f4f4f4;
  }
  .logo{
    img{
      height: 5.3vw;
      vertical-align: middle;
    }
  }
  dl{
    width: 100%;
    margin-top: -30vw;
    @include abCenter;
    text-align: center;
    dd{
      font-size: 18vw;
      color: #778190;
    }
    dt{
      h4{
        margin-top: 6vw;
        font-size: 5.4vw;
        font-weight: 500;
        color: #333333;
      }
      p{
        margin-top: 4vw;
        font-size: 4vw;
        font-weight: 500;
        color: #999999;
        line-height: 4.8vw;
        .link{
          font-size: 4vw;
          font-weight: 500;
        }
      }
    }
  }

}
</style>
