import { post} from './api.js'

export const getKey = (params, config) => { // 获取key
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/transfer/get_valid_key`, params, config)
} 

export const getFile = (params, config) => { // 通过key获取文件
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/transfer/get_file_info`, params, config)
} 

export const uploadFile = (params, config) => { // 上传文件
  return post(`${process.env.VUE_APP_BASE_QRUSER_API}/qruser/transfer/submitFile`, params, config)
} 

